import React, { Component } from 'react';

const splitTags = (tags) => tags.split(",");

const Tags = (props) => {
    console.log(splitTags(props.tags));
    return (
        <div>
            {
                props.tags &&
                splitTags(props.tags).map(tag => {
                    console.log(tag);
                    return <span 
                    key={tag}
                    style={{
                        color: 'white',
                        padding: '4px 8px 4px 8px',
                        backgroundColor: `rgb(152, 152, 152)`,
                        borderRadius: '15px',
                        fontSize: 'small',
                        margin: '0px 8px 0px 0px'
                    }}>{tag}</span>
                })
            }
        </div>
    )
}

export default Tags;