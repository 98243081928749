import React from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    RedditShareButton
} from 'react-share';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faReddit,
    faRedditAlien
} from '@fortawesome/free-brands-svg-icons'

import styled from 'styled-components';

const brandColor = {
    facebook: `#3b5998`,
	twitter: `#1da1f2`,
	googleplus: `#dd4b39`,
	linkedin: `#0077b5`,
	reddit: `#ff4500`,
	whatsapp: `#128c7e`,
};
        // padding: 5px 7px 5px 7px;
        // margin: 10px;
const StyledShareDiv = styled.div`
& .button {
    display: flex;
    justify-content: center;
    align-items: center;
}
${
Object.keys(brandColor).map(brand => {
    return `& .${brand} {
        color: ${brandColor[brand]};
        border-color: ${brandColor[brand]};
        border: 1px solid ${brandColor[brand]};
        border-radius: 50%;
        cursor: pointer;
        height: 40px;
        width: 40px;
        margin: 10px;
    }
    
    & .${brand}:hover {
        background-color: ${brandColor[brand]};
        color: white;
    }`
}).join(' ')
}
`;

const Share = (props) => {
    const {
        config
    } = props.socialConfig;
    return (
        <StyledShareDiv style={{ display: 'flex', alignItems: 'center'}}>
            <TwitterShareButton url={config.url} className={`button twitter`} hashtags={props.tags.split(", ").map(d => d.replace(/\s/g, ''))}> 
                <span className="icon"  >
                    <FontAwesomeIcon icon={faTwitter} />
                </span>
            </TwitterShareButton>
            <LinkedinShareButton url={config.url} className={`button linkedin`}> 
                <span className="icon" >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </span>
            </LinkedinShareButton>
            <RedditShareButton url={config.url} className={`button reddit`}> 
                <span className="icon" >
                    <FontAwesomeIcon icon={faRedditAlien} />
                </span>
            </RedditShareButton>
            <FacebookShareButton url={config.url} className={`button facebook`} hashtag={`#${props.tags.split(",").map(d => d.replace(/\s/g, '')).join(' #')}`}>
                <span className="icon" >
                    <FontAwesomeIcon icon={faFacebookF} />
                </span>
            </FacebookShareButton>
        </StyledShareDiv>
    )
};

export default Share;
